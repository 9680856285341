import react, { useEffect } from 'react';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { logoutUser } from '../../redux/user/user.actions';
import { authTokenSelector } from '../../redux/user/user.reselect';

const Logout = ({ logoutUser, authToken }) => {
    useEffect(() => {
        logoutUser(authToken);
    }, [])
   
    return (
        <></>
    )
}

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector
});

const mapDisptachToProps = (dispatch) => ({
    logoutUser: (authToken) => dispatch(logoutUser(authToken))
})

export default connect(mapStateToProps, mapDisptachToProps)(Logout);