import {utils, writeFile} from 'xlsx';
import lodash from 'lodash';

const exportDataToExcel = (dataExport) => {
    const data = dataExport.data;

    const timestamp = new Date().getTime();
    const header = [];
    header.push(Object.keys(data[0]).map(label => (
        lodash.startCase(label)
    )))
    
    const workBook = utils.book_new();
    const workSheet = utils.json_to_sheet(data, { origin: 'A2', skipHeader: true });
    utils.sheet_add_aoa(workSheet, header)
    utils.book_append_sheet(workBook, workSheet, "Sheet1");

    writeFile(workBook, timestamp+".xlsx");
}

export default exportDataToExcel;