import { format } from "date-fns";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { connect } from "react-redux";
import { setReportDateRange } from "../../redux/report/report.actions";

import { getToday, getThisMonth } from "../../utils/date.utils";
import CustomButton from "../custom-button/custom-button.componenet";
import DropDownButton from "../drop-down-button/drop-down-button.componenet";
import ReportDateRangeButton from "../report-date-range-button/report-date-range-button.compoenent";
import "./date-range-holder.styles.scss";

const DateRangeHolder = ({
    setReportDateRange
}) => {
    const today = getToday();
    const thisMonth = getThisMonth()
    const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: thisMonth.startDate,
            endDate: thisMonth.endDate,
            key: 'selection'
        }
    ]);

    const dateRangePickerRef = useRef(null);
    const dateRangeButtonRef = useRef(null);

    const handleSelect = () => {
        const startDate = format(selectionRange[0].startDate, "yyyy-MM-dd");
        const endDate = format(selectionRange[0].endDate, "yyyy-MM-dd");
        setReportDateRange({
            startDate,
            endDate
        })
        setIsDateRangeOpen(false)
    }

    const handleOnClickOutside = (event) => {
        if (dateRangePickerRef.current &&
            !dateRangeButtonRef.current.contains(event.target) &&
            !dateRangePickerRef.current.contains(event.target)
        ) {
            setIsDateRangeOpen(false)
        }
    }

    useEffect(() => {
        window.addEventListener("click", handleOnClickOutside);

        return () => {
            window.removeEventListener("click", handleOnClickOutside);
        }
    }, []);

    return (
        <>
            <div className="date-range-button" ref={dateRangeButtonRef}>
                <ReportDateRangeButton
                    DropDownButton={DropDownButton}
                    handleClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
                    inverted={true}
                />
            </div>
            <div className="date-range-picker-wrapper" ref={dateRangePickerRef}>
                {isDateRangeOpen &&
                    <div className="date-range-picker">
                        <DateRangePicker
                            onChange={item => setSelectionRange([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={selectionRange}
                            direction="horizontal"
                            weekStartsOn={1}
                        />
                        <div className="date-range-picker-actions">
                            <CustomButton
                                inverted={false}
                                handleClick={handleSelect}
                            >
                                Apply
                            </CustomButton>
                            <CustomButton
                                inverted={true}
                                handleClick={() => setIsDateRangeOpen(!isDateRangeOpen)}
                            >
                                Cancle
                            </CustomButton>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    setReportDateRange: (exportData) =>
        dispatch(setReportDateRange(exportData)),
});

export default connect(null, mapDispatchToProps)(DateRangeHolder)