import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const SALES_REPORT_BY_PROIFT_INFO = {
    reportTitle: "Sales Reports",
    path: "/salesreports",
    subPath: "/salesbyprofit",
    listPage: "/sales-reports",
    hasDateRange: true,
    hasChart: true,
    tableHasGrandTotal: true,
}

export const SALES_REPORT_BY_PROIFT_COLUMN_TEMPLATE = [
    {
        Header: "SO Number",
        accessor: "salesOrderNumber",
        Footer: 'Grand Total'
    },
    {
        Header: "Inventory Status",
        accessor: "inventoryStatus",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });
            const isRightAligned = true;

            if (!value) {
                return displayCellValue(value);
            }

            return (
                <div className={isMobileView && isRightAligned ?
                    'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
                    <TableCellStatus
                        value={value}
                    />
                </div>
            )
        }
    },
    {
        Header: "Customer",
        accessor: "customerName",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValue(value);
        }
    },
    {
        Header: "Order Date",
        accessor: "salesOrderDate",
        Cell: ({ value }) => {
            return displayCellValue(value);
        }
    },
    {
        Header: "Total Sales (ETB)",
        accessor: "totalSales",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return `Total Sales: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
            }

            return displayCellValueNumberToTwoDecimal(value);
        },
        Footer: 'grandTotalSales'
    },
    {
        Header: "Cost of Goods Sold (ETB)",
        accessor: "costOfGoodsSold",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return null;
            }

            return displayCellValueNumberToTwoDecimal(value);
        },
        Footer: 'grandcostOfGoodsSold'
    },
    {
        Header: "Gross Profit (ETB)",
        accessor: "grossProfit",
        Cell: ({ value }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
                return `Gross Profit: ${value ? displayCellValueNumberToTwoDecimal(value) : 0} ETB`;
            }

            return displayCellValueNumberToTwoDecimal(value);
        }
    },

]
