import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
import { useMediaQuery } from 'react-responsive';

export const INVENTORY_REPORT_REORDER_BY_ITEM_INFO = {
  reportTitle: "Stock Reports",
  path: "/inventoryreports",
  subPath: "/inventoryreorder",
  listPage: "/inventory-reports",
  hasChart: false,
  tableHasGrandTotal: false,
}

export const INVENTORY_REPORT_REORDER_BY_ITEM_COLUMN_TEMPLATE = [
  {
    Header: "Product Code",
    accessor: "itemCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
    Footer: 'Grand Total'
  },
  {
    Header: "Product Name",
    accessor: "itemName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Branch",
    accessor: "locationCode",
    Cell: ({ value }) => {
      return displayCellValue(value);
    },
  },
  {
    Header: "Reorder Level",
    accessor: "reorderLevel",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });

      if (isMobileView) {
          return `Reorder Level: ${value ? displayCellValueNumberToTwoDecimal(value) : ""}`;
      }

      return displayCellValueNumberToTwoDecimal(value);
    }
  },
  {
    Header: "Qty on Hand",
    accessor: "qtyOnHand",
    Cell: ({ value }) => {
      const isMobileView = useMediaQuery({ maxWidth: 991 });
      const isRightAligned = true;

      const newValue = { value: 1, label: isMobileView ? `${value} Quantity` :  `${value}`}

      return (
        <div className={isMobileView && isRightAligned ?
          'report-table__body__row__cell--right-aligned-mobile-cell' : ''}>
          <TableCellStatus
            value={newValue}
            noDot={true}
          />
        </div>
      )
      
    }
  }
]
