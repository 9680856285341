import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { setReportColumn, setReportDateRange, setReportInfo, setReportSideBarNav, setReportSideBarNavActiveItem } from "../../redux/report/report.actions";
import { selectReportSideBarNavActiveItem } from "../../redux/report/report.reselect";
import { setTabTitle } from '../../utils/tab-title.utils';

import { SALES_REPORT_BY_PROIFT_INFO, SALES_REPORT_BY_PROIFT_COLUMN_TEMPLATE } from './sales-report-by-profit.template';
import { SALES_REPORT_BY_PAYMENT_INFO, SALES_REPORT_BY_PAYMENT_COLUMN_TEMPLATE } from './sales-report-by-payment.template';
import { SALES_REPORT_BY_ITEM_INFO, SALES_REPORT_BY_ITEM_COLUMN_TEMPLATE } from './sales-report-by-item.template';
import { SALES_REPORT_BY_CUSTOMER_INFO, SALES_REPORT_BY_CUSTOMER_COLUMN_TEMPLATE } from './sales-report-by-customer.template';

import Report from "../../components/report/report.component";

const SUB_ITEMS = [
    {
        text: "Sales by Profit",
        info: SALES_REPORT_BY_PROIFT_INFO,
        reportColumn: SALES_REPORT_BY_PROIFT_COLUMN_TEMPLATE
    },
    {
        text: "Sales by Payment",
        info: SALES_REPORT_BY_PAYMENT_INFO,
        reportColumn: SALES_REPORT_BY_PAYMENT_COLUMN_TEMPLATE
    },
    {
        text: "Sales by Product",
        info: SALES_REPORT_BY_ITEM_INFO,
        reportColumn: SALES_REPORT_BY_ITEM_COLUMN_TEMPLATE
    },
    {
        text: "Sales by Customer",
        info: SALES_REPORT_BY_CUSTOMER_INFO,
        reportColumn: SALES_REPORT_BY_CUSTOMER_COLUMN_TEMPLATE
    }
]

const dataKeys = ["totalSales", "grossProfit"]

const SalesReport = ({
    setReportColumn,
    setReportSideBarNav,
    setReportSideBarNavActiveItem,
    reportSideBarNavActiveItem,
    setReportInfo,
    setReportDateRange,
    match
}) => {
    // const isFristRender = useRef(true);
    const [isFristRender, setIsFristRender] = useState(true);
    const endpoint = `${SUB_ITEMS[reportSideBarNavActiveItem].info.path}${SUB_ITEMS[reportSideBarNavActiveItem].info.subPath}`;

    useEffect(() => {
        setReportInfo(SUB_ITEMS[reportSideBarNavActiveItem].info);
        setReportSideBarNav(SUB_ITEMS);
    }, [reportSideBarNavActiveItem]);

    useEffect(() => {
        setReportDateRange({})
        setIsFristRender(false);
    }, [])

    useEffect(() => {
        setTabTitle(`Sales Report`)
    }, [])

    useEffect(() => {
        return () => {
            setReportInfo(null);
        }
    }, [])

    return (
        <>
            {!isFristRender &&
                <Report
                    reportColumn={SUB_ITEMS[reportSideBarNavActiveItem].reportColumn}
                    endpoint={endpoint}
                    dataKeys={dataKeys}
                />
            }
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    reportSideBarNavActiveItem: selectReportSideBarNavActiveItem
})

const mapDispatchToProps = (disptach) => ({
    setReportInfo: (reportInfo) =>
        disptach(setReportInfo(reportInfo)),
    setReportColumn: (data) =>
        disptach(setReportColumn(data)),
    setReportSideBarNav: (data) =>
        disptach(setReportSideBarNav(data)),
    setReportSideBarNavActiveItem: (data) =>
        disptach(setReportSideBarNavActiveItem(data)),
    setReportDateRange: (data) =>
        disptach(setReportDateRange(data)),

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SalesReport));