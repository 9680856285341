import { formatNumberToTwoDecimal, stringToNumber } from "../../utils/format-number.util";
import "./home-nav-card.styles.scss";

const HomeNavCard = ({
    alignCard,
    title,
    content,
    icon
}) => {
    const Icon = icon;
    return (
        <div className={`home-nav-card ${alignCard === "left" ?
            "home-nav-card--left" : alignCard === "center" ?
                "home-nav-card--center" :
                "home-nav-card--right"}`
        }>
            <div className="home-nav-card__card">
                <div className="home-nav-card__card__header">
                    <div className="home-nav-card__card__header__title">{title}</div>
                    <div className="home-nav-card__card__header__icon-wrapper">
                        {icon && <Icon className="home-nav-card__card__header__icon" />}
                    </div>
                </div>
                <div className="home-nav-card__card__body">
                    <div className="home-nav-card__card__body__currency">
                        ETB
                    </div>
                    <div className="home-nav-card__card__body__value">
                        {
                            content && stringToNumber(content) ? formatNumberToTwoDecimal(stringToNumber(content)) : 0
                        }
                    </div>

                </div>
            </div>

        </div>
    )
}

export default HomeNavCard;