import "./home-chart.styles.scss";

import DateRangeHolder from "../date-range-holder/date-range-holder.component";
import ReportLineChart from "../report-line-chart/report-line-chart.componenet";
import ButtonSpinner from "../button-spinner/button-spinner.compoenent";
import ReportBarChart from "../report-bar-chart/report-bar-chart.componenet";

const DATA_KEYS = ["grossProfit", "totalSales"]

const HomeChart = ({
    isLoading
}) => {
    return (
        <div className="home-chart">
            <div className="home-chart__date">
                {isLoading &&
                    <ButtonSpinner />
                }
                <DateRangeHolder />
            </div>
            <div className="home-chart__chart">
                {/* <ReportLineChart dataKeys={DATA_KEYS} /> */}
                <ReportBarChart dataKeys={DATA_KEYS} />
            </div>
        </div>
    )
}

export default HomeChart;