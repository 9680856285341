import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { setReportColumn, setReportDateRange, setReportInfo, setReportSideBarNav, setReportSideBarNavActiveItem } from "../../redux/report/report.actions";
import { selectReportSideBarNavActiveItem } from "../../redux/report/report.reselect";
import { setTabTitle } from '../../utils/tab-title.utils';

import { PURCHASE_REPORT_BY_ITEM_INFO, PURCHASE_REPORT_BY_ITEM_COLUMN_TEMPLATE } from './purchase-report-by-item.template';
import { PURCHASE_REPORT_BY_SUPPLIER_INFO, PURCHASE_REPORT_BY_SUPPLIER_COLUMN_TEMPLATE } from './purchase-report-by-supplier.template';


import Report from "../../components/report/report.component";

const SUB_ITEMS = [
    {
        text: "Purchasing by Product",
        info: PURCHASE_REPORT_BY_ITEM_INFO,
        reportColumn: PURCHASE_REPORT_BY_ITEM_COLUMN_TEMPLATE
    },
    {
        text: "Purchasing by Supplier",
        info: PURCHASE_REPORT_BY_SUPPLIER_INFO,
        reportColumn: PURCHASE_REPORT_BY_SUPPLIER_COLUMN_TEMPLATE
    }
]

const dataKeys = []

const PurchaseReport = ({
    setReportColumn,
    setReportSideBarNav,
    setReportSideBarNavActiveItem,
    reportSideBarNavActiveItem,
    setReportInfo,
    setReportDateRange,
    match
}) => {
    const[isFristRender, setIsFristRender] = useState(true);
    const endpoint = `${SUB_ITEMS[reportSideBarNavActiveItem].info.path}${SUB_ITEMS[reportSideBarNavActiveItem].info.subPath}`;

    useEffect(() => {
        setReportInfo(SUB_ITEMS[reportSideBarNavActiveItem].info);
        setReportSideBarNav(SUB_ITEMS);
    }, [reportSideBarNavActiveItem]);

    useEffect(() => {
        setReportDateRange({})
        setIsFristRender(false);
    }, [])

    useEffect(() => {
        setTabTitle(`Purchasing Report`)
    }, [])

    useEffect(() => {
        return () => {
            setReportInfo(null);
        }
    }, [])

    return (
        <>
            {!isFristRender &&
                <Report
                    reportColumn={SUB_ITEMS[reportSideBarNavActiveItem].reportColumn}
                    endpoint={endpoint}
                    dataKeys={dataKeys}
                />
            }
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    reportSideBarNavActiveItem: selectReportSideBarNavActiveItem
})

const mapDispatchToProps = (disptach) => ({
    setReportInfo: (reportInfo) =>
        disptach(setReportInfo(reportInfo)),
    setReportColumn: (data) =>
        disptach(setReportColumn(data)),
    setReportSideBarNav: (data) =>
        disptach(setReportSideBarNav(data)),
    setReportSideBarNavActiveItem: (data) =>
        disptach(setReportSideBarNavActiveItem(data)),
    setReportDateRange: (data) =>
        disptach(setReportDateRange(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PurchaseReport));