import { format } from 'date-fns';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import lodash from 'lodash';


const setupPdf = (dataExport) => {
    const header = Object.keys(dataExport.data[0]).map(label => (
        lodash.startCase(label)
    ));

    const body = dataExport.data.map(object => (
        Object.keys(object).map(key => (
            object[key]
        ))
    ))

    const doc = new jsPDF();

    // var totalPagesExp = "{total_pages_count_string}";
    const base64Img = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAAG1BMVEXMzMyWlpaqqqq3t7exsbGcnJy+vr6jo6PFxcUFpPI/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQUlEQVQ4jWNgGAWjgP6ASdncAEaiAhaGiACmFhCJLsMaIiDAEQEi0WXYEiMCOCJAJIY9KuYGTC0gknpuHwXDGwAA5fsIZw0iYWYAAAAASUVORK5CYII='
    const today = format(new Date(), "dd/MM/yyyy")

    autoTable(doc, {
        styles: { fontSize: 6 },
        headStyles: {
            textColor: [129, 129, 129],
            fillColor: [255, 255, 255]
        },
        head: [header],
        margin: { top: 20 },
        body,
        didDrawPage: function (data) {
            // // Header
            doc.setFontSize(16);
            doc.setTextColor(40);
            doc.text(dataExport.metaData.title, data.settings.margin.left + 0, dataExport.metaData.dateRange ? 10 : 14);

            if (dataExport.metaData.dateRange) {
                doc.setFontSize(7);
                doc.text(dataExport.metaData.dateRange, data.settings.margin.left + 0.5, 15)
            }

            doc.setFontSize(7);
            doc.text(`Generated on ${today}`,
                data.settings.margin.left + doc.internal.pageSize.getWidth() - 57, dataExport.metaData.dateRange ? 10 : 14
            )

            // Footer
            var str = "Page " + doc.internal.getNumberOfPages()
            // Total page number plugin only available in jspdf v1.0+
            // if (typeof doc.putTotalPages === 'function') {
            //     str = str + " of " + totalPagesExp;
            // }
            doc.setFontSize(7);

            // jsPDF 1.4+ uses getWidth, <1.4 uses .width
            var pageSize = doc.internal.pageSize;
            var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(str, data.settings.margin.left, pageHeight - 10);
        },
    })

    return doc;
}


export const exportDataToPdf = (data) => {
    const doc = setupPdf(data)
    const timestamp = new Date().getTime();

    doc.save(timestamp + ".pdf");

}

export const printData = (data) => {
    const doc = setupPdf(data)
    const timestamp = new Date().getTime();

    doc.autoPrint();
    doc.output('dataurlnewwindow');
}
