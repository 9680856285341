import { reportActionTypes } from "./report.types";
import modalFormSubmit from "../../services/form-submit.service";

export const setEditUUIDForm = (uuid) => ({
    type: reportActionTypes.SET_EDIT_UUID,
    payload: uuid
});

export const setFormTableData = (tableData) => ({
    type: reportActionTypes.SET_FORM_TABLE_DATA,
    payload: tableData
});

export const setEditDataForm = (data) => ({
    type: reportActionTypes.SET_EDIT_DATA,
    payload: data
});

export const setIsFormFetching = (isFetching) => ({
    type: reportActionTypes.SET_IS_FETCHING,
    payload: isFetching
});

export const setFormExpandSection = (sectionTypeId, isExpand) => ({
    type: reportActionTypes.SET_FORM_EXPAND_SECTION,
    sectionTypeId: sectionTypeId,
    isExpand: isExpand
});

export const setFormType = (formType) => ({
    type: reportActionTypes.SET_FORM_TYPE,
    payload: formType
})

export const setReportColumn = (reportColumn) => ({
    type: reportActionTypes.SET_REPORT_COLUMN,
    payload: reportColumn
})

export const setFormEdit = (formEdit) => ({
    type: reportActionTypes.SET_FORM_EDIT,
    payload: formEdit
})

export const setFormTableLatestData = (formTableLatestData) => ({
    type: reportActionTypes.SET_FORM_LATEST_DATA,
    payload: formTableLatestData
})

export const setFormTableLatestRows = (tableLatestRow) => ({
    type: reportActionTypes.SET_TABLE_LATEST_ROWS,
    payload: tableLatestRow
})

// const submitFormStart = () => ({
//     type: reportActionTypes.SUBMIT_FORM_START
// })

// const submitFormSuccess = () => ({
//     type: reportActionTypes.SUBMIT_FORM_SUCCESS
// })

// const submitFormFailure = (errorMessage) => ({
//     type: reportActionTypes.SUBMIT_FORM_FAILURE,
//     payload: errorMessage
// })

// export const submitForm = (formValue, authTokenFromState, url, formEdit) => {
//     return async dispatch => {
//         try {
//             dispatch(submitFormStart());
//             // const response = await formSubmit(formValue, authTokenFromState, url, formEdit);
//             dispatch(submitFormSuccess());

//             // return response;
//         } catch (error) {
//             dispatch(submitFormFailure(error.message))
//             throw new Error();
//         }
//     }
// }

export const resetErrorMessageAndIsFail = () => ({
    type: reportActionTypes.RESET_ERROR_MESSAGE_AND_IS_FAIL,
})

export const setIsFailForm = (error) => ({
    type: reportActionTypes.SET_IS_FAIL,
    payload: error
})

export const setFromValidationError = (errors) => ({
    type: reportActionTypes.SET_FORM_VALIDATION_ERROR,
    payload: errors
})

export const setWindowHeightForm = (height) => ({
    type: reportActionTypes.SET_WINDOW_HEIGHT,
    payload: height
});

export const addRowFormTable = () => ({
    type: reportActionTypes.ADD_ROW_FORM_TABLE,
})

export const removeRowFormTable = (data) => ({
    type: reportActionTypes.REMOVE_ROW_FORM_TABLE,
    payload: data
})

export const setFormTotalValues = (data) => ({
    type: reportActionTypes.SET_FORM_TOTAL_VALUES,
    payload: data
})

export const setFormChangedValues = (data) => ({
    type: reportActionTypes.SET_FORM_CHANGED_VALUES,
    payload: data
})

export const setIsEditByFullForm = (data) => ({
    type: reportActionTypes.SET_IS_EDIT_FULL_FORM,
    payload: data
})

export const setReportInfo = (data) => ({
    type: reportActionTypes.SET_REPORT_INFO,
    payload: data
})

export const setSelectedField = (data) => ({
    type: reportActionTypes.SET_SELECTED_FIELD,
    payload: data
});

export const setSelectedFieldValue = (data) => ({
    type: reportActionTypes.SET_SELECTED_FIELD_VALUE,
    payload: data
})

export const setReportSideBarNav = (data) => ({
    type: reportActionTypes.SET_REPORT_SIDE_BAR_NAV,
    payload: data
});

export const setReportSideBarNavActiveItem = (data) => ({
    type: reportActionTypes.SET_REPORT_SIDE_BAR_NAV_ACTIVE_ITEM,
    payload: data
})

export const setFormProgress = (data) => ({
    type: reportActionTypes.SET_FORM_PROGRESS,
    payload: data
})

export const removeRemoveTableRows = () => ({
    type: reportActionTypes.SET_REMOVE_REMOVE_TABLE_ROW
})

export const setFormTemplateEnableField = (data) => ({
    type: reportActionTypes.SET_FORM_TEMPLATE_ENABLE_FIELD,
    payload: data
});


export const setValueEntredBlur = (data) => ({
    type: reportActionTypes.SET_VALUE_ENTERED_BLUR,
    payload: data
})

export const setReportDateRange = (data) => ({
    type: reportActionTypes.SET_REPORT_DATE_RANGE,
    payload: data
})

export const setReportSummaryData = (data) => ({
    type: reportActionTypes.SET_REPORT_SUMMARY_DATA,
    payload: data
})
