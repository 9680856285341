import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";

import './form.styles.scss';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSSTransition } from 'react-transition-group';
import { setIsFail } from '../../redux/modal/modal.actions';
import { selectEditUUID, selectIsSaved, selectWindowHeight } from '../../redux/modal/modal.reselect';
import { authTokenSelector } from '../../redux/user/user.reselect';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { setRefresh } from '../../redux/refresh/refresh.actions';
import { fetchItem, fetchItems, setLoadedData } from '../../redux/list/list.actions';
import { useRef } from 'react';
import FormHeader from '../form-header/form-header.componenet';
import { selectFormEdit, selectFormInfo, selectFormTableData, selectFormTemplate, selectRemoveTableRow, selectValueEntredBlur } from '../../redux/form/form.reselect';
import FormBody from '../form-body/form-body.componenet';
import { resetErrorMessageAndIsFail, setFormTableData, setFormTableLatestData, setFormTableLatestRows, setFromValidationError, setSelectedField, setSelectedFieldValue, setValueEntredBlur, submitForm, submitFormForComplete } from '../../redux/form/form.actions';
import { isArrayOfObjectEqual } from '../../utils/array-values.utils';
import { selectPagePath } from '../../redux/list/list.reselect';
import { selectIsRefresh } from '../../redux/refresh/refresh.reselect';
import FormSideBar from '../form-side-bar/form-side-bar.component';
import { useState } from 'react';
import ReportBody from '../report-body/report-body.component';
import { setFilterEmpty } from '../../redux/filter/filter.actions';

const Form = ({
    setShowModal,
    showModal,
    submitForm,
    submitFormForComplete,
    formInfo,
    formTemplate,
    formTableData,
    setFromValidationError,
    resetErrorMessageAndIsFail,
    isSaved,
    setIsFail,
    history,
    windowHeight,
    endpoint,
    endpointForQuickComplete,
    endpointForQuickPay,
    formEdit,
    authToken,
    isLoading,
    isRefresh,
    setRefresh,
    fetchItem,
    editUUID,
    size,
    setFormTableLatestData,
    tableLatestRows,
    setFormTableLatestRows,
    removeTableRow,
    pagePath,
    valueEntredBlur,
    setValueEntredBlur,
    setSelectedFieldValue,
    setSelectedField,
    isViewOnly,
    isNoDeactivate,
    showReport,
    reportColumn,
    reportEndpoint,
    setLoadedData,
    setFilterEmpty,
    dataKeys,
    hasNoDate
}) => {

    const { bodySections, watchFields } = formTemplate;
    const { formTitle, formTitleForNew, listPage } = formInfo;
    const historyHook = useHistory();
    const methods = useForm({
        shouldUnregister: true
    });

    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
    const [isSideNavOn, setIsSideNavOn] = useState(true);

    const handleSideNav = () => {
        setIsSideNavOn((prevState) => !prevState)
    }

    const saveData = async (formValues) => {
        try {
            setFromValidationError(null);
            const response = await submitForm(
                formValues,
                authToken,
                endpoint,
                formEdit
            );

            if (response.status == 200 || response.status == 201) {
                toast.success('Data Successfully Saved');
                setFromValidationError(null);
                setIsFail(null);
                setRefresh(true);
                setIsSubmitSuccessful(true);
            }

            return response.data;
        } catch (error) {
            console.log(error);
            window.scrollTo(0, 0);
            throw new Error();
        }
    }

    const handleSubmitModalFormSave = async (formValues) => {
        try {
            const { uuid } = await saveData(formValues);

            if (!formEdit) {
                setRefresh(true);
                history.replace(`${listPage}/${uuid}`);
            } else {
                fetchItem(authToken, endpoint);
            }
        } catch (error) {
            console.log("netowrk error has occured:")
            console.log(error);
        }
    }

    const handleSubmitModalFormError = async (errors) => {
        window.scrollTo(0, 0);
        setFromValidationError(errors);
    }

    const completeData = async (formValues) => {
        try {
            setFromValidationError(null);
            const response = await submitForm(
                formValues,
                authToken,
                `${endpoint}/complete`,
                formEdit
            );

            if (response.status == 200 || response.status == 201) {
                toast.success('Data Successfully Saved');
                setFromValidationError(null);
                // setIsFail(null);
                // setRefresh(true);
                // setIsSubmitSuccessful(true);
            }

            return response.data;
        } catch (error) {
            console.log(error);
            window.scrollTo(0, 0);
            throw new Error();
        }
    }

    const handleSubmitModalFormComplete = async (formValues) => {
        try {
            const { uuid } = await completeData(formValues);

            if (!formEdit) {
                setRefresh(true);
                history.replace(`${listPage}/${uuid}`);
            } else {
                fetchItem(authToken, endpoint);
            }
        } catch (error) {
            console.log("netowrk error has occured:")
            console.log(error);
        }
    }

    const payData = async (formValues) => {
        try {
            setFromValidationError(null);
            const response = await submitForm(
                formValues,
                authToken,
                `${endpoint}/pay`,
                formEdit
            );

            if (response.status == 200 || response.status == 201) {
                toast.success('Data Successfully Saved');
                setFromValidationError(null);
                // setIsFail(null);
                // setRefresh(true);
                // setIsSubmitSuccessful(true);
            }

            return response.data;
        } catch (error) {
            console.log(error);
            window.scrollTo(0, 0);
            throw new Error();
        }
    }

    const handleSubmitModalFormPay = async (formValues) => {
        try {
            const { uuid } = await payData(formValues);

            if (!formEdit) {
                setRefresh(true);
                history.replace(`${listPage}/${uuid}`);
            } else {
                fetchItem(authToken, endpoint);
            }
        } catch (error) {
            console.log("netowrk error has occured:")
            console.log(error);
        }
    }

    const handleCloseForm = (event) => {
        event.preventDefault();
        methods.reset();
        setFromValidationError(null);
        setIsFail(null);
        history.push(historyHook.goBack());
    }

    const handleAddNew = (event) => {
        event.preventDefault();
        methods.reset();
        setFromValidationError(null);
        setIsFail(null);
        history.push(`${formInfo.listPage}/new`);
    }

    useEffect(() => {
        if (valueEntredBlur === true) {
            const formValues = methods.getValues();
            const items = formValues.items;
            const itemsFiltered = items ? items.filter(item => item) : [];
            setFormTableLatestRows(itemsFiltered);

            const newWatchFields = []
            const watchedFields = watchFields.map(watchField => {
                const splitedField = watchField.split(".");
                const sectionName = splitedField[0];
                const indexCounter = splitedField[1]
                const fieldName = splitedField[splitedField.length - 1];

                if (!formValues[sectionName] || !formValues[sectionName][indexCounter]) {
                    return null
                }

                formValues[sectionName][indexCounter][fieldName] && newWatchFields.push(watchField);

                return formValues[sectionName][indexCounter][fieldName];
            }).filter((watchField) => watchField);

            const watchFieldsObject = newWatchFields.reduce((prevObject, field, index) => {
                if (!(field.includes("."))) {
                    return ({
                        ...prevObject,
                        [field]: watchedFields[index]
                    })
                }

                const splitedField = field.split(".");
                const fieldName = splitedField[splitedField.length - 1];

                return ({
                    ...prevObject,
                    [fieldName]: watchedFields[index]
                })

            }, {})
            setFormTableLatestData(watchFieldsObject);

            setValueEntredBlur(false)
        }
    }, [valueEntredBlur])

    useEffect(() => {
        if (isRefresh) {
            setFromValidationError(null);
            resetErrorMessageAndIsFail();
            setSelectedFieldValue(null)
            setRefresh(false);
        }
    }, [isRefresh])

    useEffect(() => {
        if (isSubmitSuccessful) {
            methods.reset({});
            setIsSubmitSuccessful(false)
        }
    }, [isSubmitSuccessful])

    useEffect(() => {
        if (!formEdit) {
            setLoadedData({ data: null, skip: null, totalNumItems: 0 });
            setFilterEmpty();
        }
    }, [])

    useEffect(() => {
        return () => {
            setFromValidationError(null);
            resetErrorMessageAndIsFail();
            methods.reset({ items: "" });
        }
    }, []);

    return (
        <> {
            formTemplate || reportColumn ?
                <>
                    <div className="form">
                        <FormProvider {...methods}>
                            {formTemplate &&
                                <FormHeader
                                    formTitle={formEdit ? formTitle : formTitleForNew}
                                    handleCloseForm={handleCloseForm}
                                    handleAddNew={handleAddNew}
                                    handleSave={methods.handleSubmit(handleSubmitModalFormSave, handleSubmitModalFormError)}
                                    handleComplete={methods.handleSubmit(handleSubmitModalFormComplete, handleSubmitModalFormError)}
                                    handlePay={methods.handleSubmit(handleSubmitModalFormPay, handleSubmitModalFormError)}
                                    handleSideNav={handleSideNav}
                                    isViewOnly={isViewOnly}
                                    isNoDeactivate={isNoDeactivate}
                                    endpointForQuickComplete={endpointForQuickComplete}
                                    endpointForQuickPay={endpointForQuickPay}
                                    quickComplete={formTemplate.quickComplete}
                                    quickPay={formTemplate.quickPay}
                                    toast={toast}
                                />
                            }
                            <div className="form__nav-body">
                                <form className={`form__nav-body__nav ${!formEdit && "form__nav-body__nav--none"}`}>
                                    {isSideNavOn && formEdit && <FormSideBar />}
                                </form>
                                {
                                    !showReport ?
                                        <form className={`form__nav-body__body ${!formEdit && "form__nav-body__body--full"}  
                                        ${isSideNavOn ? null : "form__nav-body__body--full"}
                                    `}>
                                            <FormBody
                                                bodySections={bodySections}
                                                isLoading={isLoading}
                                                formTableData={formTableData}
                                                endpoint={endpoint}
                                                isViewOnly={isViewOnly}
                                            />
                                        </form>
                                        :
                                        <ReportBody
                                            reportColumn={reportColumn}
                                            endpoint={reportEndpoint}
                                            dataKeys={[]}
                                            hasNoDate={true}
                                        />
                                }
                            </div>
                        </FormProvider>
                    </div>
                    {
                        isSaved &&
                        <ToastContainer
                            hideProgressBar={true}
                            limit={3}
                        />
                    }
                </> : null
        }

        </>
    )
}

const mapStateToProps = createStructuredSelector({
    formInfo: selectFormInfo,
    formTemplate: selectFormTemplate,
    isSaved: selectIsSaved,
    windowHeight: selectWindowHeight,
    authToken: authTokenSelector,
    formEdit: selectFormEdit,
    editUUID: selectEditUUID,
    removeTableRow: selectRemoveTableRow,
    pagePath: selectPagePath,
    isRefresh: selectIsRefresh,
    formTableData: selectFormTableData,
    valueEntredBlur: selectValueEntredBlur,
    // tableLatestRows: selectFormTableLatestRows
});

const mapDispatchToProps = dispatch => ({
    submitForm: (formValues, authToken, path, modalEdit) =>
        dispatch(submitForm(formValues, authToken, path, modalEdit)),
    submitFormForComplete: (formValues, authToken, path, modalEdit) =>
        dispatch(submitFormForComplete(formValues, authToken, path, modalEdit)),
    setFromValidationError: (errors) =>
        dispatch(setFromValidationError(errors)),
    resetErrorMessageAndIsFail: () =>
        dispatch(resetErrorMessageAndIsFail()),
    setIsFail: (error) =>
        dispatch(setIsFail(error)),
    setRefresh: (data) =>
        dispatch(setRefresh(data)),
    fetchItem: (authToken, url) =>
        dispatch(fetchItem(authToken, url)),
    setFormTableLatestData: (formTableLatestData) =>
        dispatch(setFormTableLatestData(formTableLatestData)),
    setFormTableLatestRows: (formTableLatestData) =>
        dispatch(setFormTableLatestRows(formTableLatestData)),
    setValueEntredBlur: (data) =>
        dispatch(setValueEntredBlur(data)),
    setSelectedFieldValue: (data) =>
        dispatch(setSelectedFieldValue(data)),
    setSelectedField: (data) =>
        dispatch(setSelectedField(data)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data)),
    setFilterEmpty: () =>
        dispatch(setFilterEmpty()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));