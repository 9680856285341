import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const ITEM_GROUP_INFO = {
  formTitle: "Category",
  formTitleForNew: "Category",
  path: "/itemgroups",
  listPage: "/item-groups",
}

export const ITEM_GROUP_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "itemGroup",
      fieldItems: [
        {
          label: "Category code",
          type: "text",
          name: "itemGroupCode",
          initialFocus: false,
          validationProps: {
            required: "Category code is required"
          }
        },
        {
          label: "Category Name",
          type: "text",
          name: "itemGroupName",
          initialFocus: false,
          validationProps: {
            required: "Category name is required"
          }
        }
      ]
    }
  },
  watchFields: []
}
