import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { setReportColumn, setReportDateRange, setReportInfo, setReportSideBarNav, setReportSideBarNavActiveItem } from "../../redux/report/report.actions";
import { selectReportSideBarNavActiveItem } from "../../redux/report/report.reselect";
import { setTabTitle } from '../../utils/tab-title.utils';

import { INVENTORY_REPORT_REORDER_BY_ITEM_INFO, INVENTORY_REPORT_REORDER_BY_ITEM_COLUMN_TEMPLATE } from './inventory-report-reorder.template';
import { PRODUCTION_REPORT_BY_ORDER_INFO, PRODUCTION_REPORT_BY_ORDER_COLUMN_TEMPLATE } from './production-report.template';
import { EXPIRATION_REPORT_BY_ORDER_INFO, EXPIRATION_REPORT_BY_ORDER_COLUMN_TEMPLATE } from './expiration-report.template';
import { STOCK_TRANSFER_REPORT_BY_ORDER_INFO, STOCK_TRANSFER_REPORT_BY_ORDER_COLUMN_TEMPLATE } from './stock-transfer-report.template';


import Report from "../../components/report/report.component";

const SUB_ITEMS = [
    {
        text: "Stock Reorder",
        info: INVENTORY_REPORT_REORDER_BY_ITEM_INFO,
        reportColumn: INVENTORY_REPORT_REORDER_BY_ITEM_COLUMN_TEMPLATE
    },
    {
        text: "Stock Transfer",
        info: STOCK_TRANSFER_REPORT_BY_ORDER_INFO,
        reportColumn: STOCK_TRANSFER_REPORT_BY_ORDER_COLUMN_TEMPLATE
    },
    {
        text: "Product Expiration",
        info: EXPIRATION_REPORT_BY_ORDER_INFO,
        reportColumn: EXPIRATION_REPORT_BY_ORDER_COLUMN_TEMPLATE
    },
    {
        text: "Production by Order",
        info: PRODUCTION_REPORT_BY_ORDER_INFO,
        reportColumn: PRODUCTION_REPORT_BY_ORDER_COLUMN_TEMPLATE
    },
]

const dataKeys = []

const InventoryReport = ({
    setReportColumn,
    setReportSideBarNav,
    setReportSideBarNavActiveItem,
    reportSideBarNavActiveItem,
    setReportInfo,
    setReportDateRange,
    match
}) => {
    const [isFristRender, setIsFristRender] = useState(true);
    const endpoint = `${SUB_ITEMS[reportSideBarNavActiveItem].info.path}${SUB_ITEMS[reportSideBarNavActiveItem].info.subPath}`;

    useEffect(() => {
        setReportInfo(SUB_ITEMS[reportSideBarNavActiveItem].info);
        setReportSideBarNav(SUB_ITEMS);
    }, [reportSideBarNavActiveItem]);

    useEffect(() => {
        setReportDateRange({})
        setIsFristRender(false);
    }, [])

    useEffect(() => {
        setTabTitle(`Stock Report`)
    }, [])

    useEffect(() => {
        return () => {
            setReportInfo(null);
        }
    }, [])

    return (
        <>
            {!isFristRender &&
                <Report
                    reportColumn={SUB_ITEMS[reportSideBarNavActiveItem].reportColumn}
                    endpoint={endpoint}
                    dataKeys={dataKeys}
                />
            }
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    reportSideBarNavActiveItem: selectReportSideBarNavActiveItem
})

const mapDispatchToProps = (disptach) => ({
    setReportInfo: (reportInfo) =>
        disptach(setReportInfo(reportInfo)),
    setReportColumn: (data) =>
        disptach(setReportColumn(data)),
    setReportSideBarNav: (data) =>
        disptach(setReportSideBarNav(data)),
    setReportSideBarNavActiveItem: (data) =>
        disptach(setReportSideBarNavActiveItem(data)),
    setReportDateRange: (data) =>
        disptach(setReportDateRange(data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InventoryReport));