import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import TableCountCell from '../../../components/table-count-cell/table-count-cell.componet';
import { useMediaQuery } from 'react-responsive';

export const SALES_ORDER_INFO = {
  formTitle: "Sales Order",
  formTitleForNew: "New Sales Order",
  path: "/salesorders",
  listPage: "/sales-orders"
}

export const SALES_ORDER_FORM_TEMPLATE = {
  quickComplete: true,
  quickPay: true,
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "salesOrder",
      fieldItems: [
        {
          label: "SO Number",
          type: "text",
          name: "salesOrderNumber",
          initialFocus: false,
          disabled: true,
          defaultValue: `SO-`
        },
        {
          label: "Branch",
          type: "select",
          name: "Location",
          initialFocus: false,
          muliselect: false,
          url: "/locations",
          validationProps: {
            required: "Branch is required"
          }
        },
        {
          label: "SO Date",
          type: "date",
          name: "salesOrderDate",
          initialFocus: false,
          validationProps: {
            required: "SO date is required"
          }
        },
        {
          label: "Customer Name/TIN/Phone",
          type: "select",
          name: "Customer",
          required: false,
          initialFocus: false,
          muliselect: false,
          url: "/customers",
        },
      ]
    },
    tableForm: {
      sectionType: "Products",
      sectionTypeId: "tableForm",
      expandable: true,
      isExpand: true,
      showTotal: true,
      isCurrency: true,
      fieldName: "items",
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Product Category/Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 250,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Product Code/Name is required"
            }
            
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Product Code/Name"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Quantity"}
                required={true}
              />
            )
          },
          accessor: "qty",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Quantity is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qty"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
              headerName={"Quantity"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "unitOfMeasure",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/unitofmeasures";
            const validationProps = {
              required: "UoM is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={true}
              headerName={"UoM"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Unit Price (ETB)"}
                required={true}
              />
            )
          },
          accessor: "unitPrice",
          width: 140,
          Cell: ({ row, cell }) => {
            const validationProps = {
              required: "Unit price is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"unitPrice"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
              headerName={"Unit Price (ETB)"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: "Subtotal (ETB)",
          accessor: "subTotal",
          width: 140,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (
              <FormTableCalculateDisplay
                fieldName={"items"}
                rowNumber={row.id}
                calculateType="multiply"
                valuesToCalculate={["qty", "unitPrice"]}
              />
            )
          }
        },
  
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    approvalDetails: {
      sectionType: "Approval details",
      sectionTypeId: "approvalDetails",
      multiForm: false,
      expandable: true,
      isExpand: true,
      showEditOnly: true,
      addButton: true,
      breakPoint: 1,
      fieldName: "approvalStatus",
      fieldItems: [
        {
          label: "Current Approval Status",
          type: "select",
          name: "ApprovalStatus",
          initialFocus: false,
          muliselect: false,
          defaultValue: { label: 'Unapproved', value: 2 },
          url: "/approvalstatus"
        }
      ]
    },
    customerDetails: {
      sectionType: "Add New Customer",
      sectionTypeId: "customerDetails",
      multiForm: false,
      mutliFormTitle: "Customer",
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 2,
      fieldName: "customer",
      fieldItems: [
        {
          label: "Customer Name",
          type: "text",
          name: "customerName",
          initialFocus: false,
        },
        {
          label: "Customer TIN",
          type: "text",
          name: "customerTin",
          initialFocus: false,
        },
        {
          label: "Customer Phone Number",
          type: "text",
          name: "customerPhone",
          initialFocus: false,
        },
      ]
    },
    itemTaxDetails: {
      sectionType: "Item Tax",
      sectionTypeId: "itemTaxDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "itemTax",
      fieldItems: [
        {
          label: "Item Tax",
          type: "select",
          name: "ItemTax",
          initialFocus: false,
          muliselect: false,
          hasDefaultValue: true,
          defaultValueUrl: "/itemtaxesdefault",
          url: "/itemtaxes",
          defaultValue: { label: 'Excluding Tax', value: 0 },
          validationProps: {
            required: "Item Tax is required"
          }
        }
      ]
    },
    shippingDetails: {
      sectionType: "Shipping",
      sectionTypeId: "shippingDetails",
      multiForm: false,
      mutliFormTitle: "Shipping",
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "shipping",
      fieldItems: [
        {
          label: "Shipping Cost (ETB)",
          type: "text",
          name: "shippingCost",
          initialFocus: false,
        },
      ]
    },
    salesInvoiceDetails: {
      sectionType: "Invoice",
      sectionTypeId: "invoiceDetails",
      multiForm: false,
      mutliFormTitle: "",
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "invoice",
      fieldItems: [
        {
          label: "Invoice Date",
          type: "date",
          name: "invoicedDate",
          initialFocus: false,
        },
        {
          label: "Invoice Number",
          type: "text",
          name: "fsNumber",
          initialFocus: false,
        },
      ]
    },
    receiptReDetails: {
      sectionType: "Receipt",
      sectionTypeId: "receiptDetails",
      multiForm: false,
      mutliFormTitle: "",
      expandable: true,
      isExpand: false,
      addButton: true,
      breakPoint: 1,
      fieldName: "receipt",
      fieldItems: [
        {
          label: "Receipt Date",
          type: "date",
          name: "receiptDate",
          initialFocus: false,
        },
        {
          label: "Receipt Number",
          type: "text",
          name: "receiptNumber",
          initialFocus: false,
        },
      ]
    },
    auditHistoryDetails: {
      sectionType: "Audit History",
      sectionTypeId: "auditHistoryDetails",
      multiForm: false,
      expandable: true,
      isExpand: false,
      showEditOnly: true,
      addButton: true,
      position: "LAST",
      breakPoint: 2,
      fieldName: "auditHistory",
      fieldItems: [
        {
          label: "Created By",
          type: "text",
          name: "createdBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Updated By",
          type: "text",
          name: "updatedBy",
          initialFocus: false,
          disabled: true
        },
        {
          label: "Approved By",
          type: "text",
          name: "approvedBy",
          initialFocus: false,
          disabled: true
        },
      ]
    },
  },
  watchFields: [
    "shipping.0.shippingCost",
    "itemTax.0.ItemTax",
  ],

}
