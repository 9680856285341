import listActionTypes from "./list.types";
import fetchListItem from "../../services/fetch-item.service";

export const setListType = (listType) => ({
    type: listActionTypes.SET_LIST_TYPE,
    payload: listType
})

export const setSelectedRowsUUID = (selectedRowsUUID) => ({
    type: listActionTypes.SET_SELECTED_ROWS_UUID,
    payload: selectedRowsUUID
})

export const setLoadedData = (loadedData) => ({
    type: listActionTypes.SET_LOADED_DATA,
    payload: loadedData
})

export const setScrollPosition = (position) => ({
    type: listActionTypes.SET_SCROLL_POSITION,
    position
})

export const setTotalNumItems = (totalNumItems) => ({
    type: listActionTypes.SET_TOTAL_NUM_ITEMS,
    totalNumItems
})


const fetchItemsStart = () => ({
    type: listActionTypes.FETCH_ITEMS_START
})

const fetchItemsSuccess = (response) => ({
    type: listActionTypes.FETCH_ITEMS_SUCCESS,
    payload: response
})

const fetchItemsFailure = (errorMessage) => ({
    type: listActionTypes.FETCH_ITEMS_FAILURE,
    payload: errorMessage
})

export const fetchItem = (authTokenFromState, url) => {
    return async dispatch => {
        try{
            dispatch(fetchItemsStart());
            const response = await fetchListItem(authTokenFromState, url);
            dispatch(fetchItemsSuccess(response));
            return response;
        } catch(error){
            dispatch(fetchItemsFailure(error.message))
            throw error;
        }
    }
}

export const removeEditedItem = () => ({
    type: listActionTypes.REMOVE_EDITED_ITEM
})

export const resetList = () => ({
    type: listActionTypes.REST_LIST
})

export const setPagePath = (path) => ({
    type: listActionTypes.SET_PAGE_PATH,
    payload: path
})

export const setDeactivateCheck = (data) => ({
    type: listActionTypes.SET_DEACTIVATE_CHECK,
    payload: data
})