import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const UNSTOCK_INFO = {
  formTitle: "Purchase Order",
  path: "/purchaseorders",
  subPath: "/unstocks",
  listPage: "/purchase-orders",
}

export const UNSTOCK_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "purchaseOrder",
      fieldItems: [
        {
          label: "PO Number",
          type: "text",
          name: "purchaseOrderNumber",
          initialFocus: true,
          disabled: true,
          defaultValue: `PO-.YYYY.-`
        },
        {
          label: "PO Date",
          type: "date",
          name: "purchaseOrderDate",
          initialFocus: false,
          disabled: true,
          validationProps: {
            required: "PO date is required"
          }
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   disabled: true,
        //   muliselect: false,
        //   url: "/users"
        // }
      ]
    },
    tableForm: {
      sectionType: "Items",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: true,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Product Code/Name is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Product Code/Name"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "unitOfMeasure",
          width: 80,
          Cell: ({ row }) => {
            const endpointUrl = "/unitofmeasures";
            const validationProps = {
              required: "UoM is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Unstock Qty"}
                required={false}
              />
            )
          },
          accessor: "unstockQty",
          width: 70,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"unstockQty"}
              rowNumber={row.id}
              valueType={"number"}
              align="right"
              disabled={row.isDisable}
              headerName={"Unstock Qty"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Branch"}
                required={true}
              />
            )
          },
          accessor: "location",
          width: 170,
          Cell: ({ row }) => {
            const endpointUrl = "/locations";
            const validationProps = {
              required: "Branch is required"
            }
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Location"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Branch"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Unstocked By"}
                required={false}
              />
            )
          },
          accessor: "returnedBy",
          width: 90,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"unstockedBy"}
              rowNumber={row.id}
              validationProps={false}
              disabled={true}
              align="left"
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Unstock Date"}
                required={true}
              />
            )
          },
          accessor: "unstockDate",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Unstock date is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableDatePicker
              fieldName={"items"}
              fieldItemName={"unstockDate"}
              rowNumber={row.id}
              value={row.value}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Unstock Date"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
  },
  watchFields: []
}
