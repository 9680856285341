import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'


import './log-in-form.styles.scss';

import LogInFormInput from '../log-in-form-input/log-in-form-input.component';
import LogInCustomButton from '../log-in-custom-button/log-in-custom-button.component';
import LogInCustomCheckbox from '../log-in-custom-checkbox/log-in-custom-checkbox.componenet';
import FormError from '../form-error/form-error.compoenent';

import { loginUser } from '../../redux/user/user.actions';
import { userSelector } from '../../redux/user/user.reselect';


const schema = yup.object().shape({
    username: yup.string().required("Please enter Username, Email or Phone number."),
    password: yup.string().required("Please enter password.")
})

const LogInForm = ({ 
    loginUser, 
    history, 
    user: { 
        isLoading, 
        isFail, 
        messageUser 
    } 
}) => {
    const { register, handleSubmit, formState: { errors} } = useForm({
        resolver: yupResolver(schema)
    });
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState('');

    const handleKeyDown = (event) => {
        if (event.key.toLowerCase() === "enter"){
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();

            if(index === 1){
                return null;
            }
            event.preventDefault();
        }
    }

    const submitLoginForm = async (event) => {
        try {
            const response = await loginUser({ username, password, rememberMe});
         
            if(response){
                history.push("/home");
            }
        } catch(error){
            console.log(error.message)
        }
    }
    return (  
        <div className='log-in-form'>
            <form onSubmit={ handleSubmit(submitLoginForm) }>
                {
                    isFail &&  
                    <div className='log-in-form__general-error'>
                        <FormError errorMessage= { messageUser }/>
                    </div>
                }
                <LogInFormInput 
                    name="username"
                    type="text"
                    value={username}
                    handleChange={ event => setUsername(event.target.value)}
                    handleKeyDown={ handleKeyDown }
                    label="Username, Email, or Phone Number"
                    initialFocus = { true }
                    register = { register }
                />
                <div className='log-in-form__input-error'>
                    {errors.username && <FormError errorMessage= {errors.username.message}/>}
                </div>
                <LogInFormInput 
                    name="password"
                    type="password"
                    value={password}
                    handleChange={ event => setPassword(event.target.value)}
                    handleKeyDown={ handleKeyDown }
                    label="Password"
                    register = { register }
                />
                <div className='log-in-form__input-error'>
                    {errors.password && <FormError errorMessage= {errors.password.message}/>}
                </div>
                <div className='log-in-form__buttons'>
                    <LogInCustomButton 
                        type="submit"  
                        isLoading = { isLoading }
                    >
                        Log In
                    </LogInCustomButton>
                </div>
                <div className='log-in-form__radio'>
                    <LogInCustomCheckbox 
                        name="remember-me"
                        value={ rememberMe }
                        checked = { rememberMe }
                        handleChange={ () => rememberMe ? setRememberMe(false) : setRememberMe(true)}
                        label="Remember me"
                       
                    />
                </div>
            </form>
        </div>
    )
}


const mapStateToProps= createStructuredSelector({
    user: userSelector
})

const mapDispatchToProps = dispatch => ({
    loginUser : user => dispatch(loginUser(user))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogInForm));