import React from 'react';

import './log-in.styles.scss';

import Logo from '../../components/logo/logo.componenet';
import LogInForm from '../../components/log-in-form/log-in-form.component';


const LogIn = () => (
    <div className="log-in">
       <div className="container">
           <div className='row align-items-center log-in__wraper'>
                <div className='col-12'>
                    <div className='row justify-content-center'>
                        <div className='col-12 log-in__logo-img-wraper'>
                            <div className="log-in__logo-img">
                                <Logo width="250"/>
                            </div>
                          
                        </div>
                    </div>
                    <div className='row justify-content-center '>
                        <div className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5'>
                            <LogInForm />
                        </div>
                    </div>
                </div>
           </div>
       </div>
    </div>
);

export default LogIn;


