import React from 'react';
import NavDropdown from '../nav-dropdown/nav-dropdown.components';
import IconDownArrow from '../../assets/icons/down-arrow.svg';
import IconDownArrowFilled from '../../assets/icons/down-arrow-filled.svg';
import { MdOutlineArrowDropUp } from "react-icons/md";

import './nav-item.styles.scss';
import { useRef } from 'react';
import { useState } from 'react';
import useOutSideClick from '../../utils/out-side-click.util';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setBurgerHidden } from '../../redux/navigation/navigation.actions';

const NavItem = ({
    navItem,
    handleBurgerClick,
    setBurgerHidden
}) => {
    const history = useHistory();

    const [isDropDown, setIsDropDown] = useState(false);
    const navItemRef = useRef(null);

    const handleCollabseDropDown = () => {
        setIsDropDown(false);
    }

    useOutSideClick(navItemRef, handleCollabseDropDown)

    const handleNavClick = () => {
        setIsDropDown(!isDropDown);
    }

    const handleNavClickWithoutDropDown = (event) => {
        history.push(navItem.page);
        setBurgerHidden();
    }

    return (
        <li className="nav-item" href="#" ref={navItemRef} onClick={navItem.dropDownItems ? handleNavClick : handleNavClickWithoutDropDown}>
            <button
                className="nav-item__link" 
                data-page = {navItem.dropDownItems ? null : navItem.page}
            >
                {navItem.itemName}
                {/* {navItem.dropDownItems &&
                    // <img src={IconDownArrowFilled}
                    //     className="nav-item__icon"
                    //     alt="Icon Expand Arrow"
                    //     style={!isDropDown ? null : { transform: 'rotate(180deg)' }}
                    // />
                } */}


                    {navItem.dropDownItems && <MdOutlineArrowDropUp 
                         className="nav-item__icon"
                         style={!isDropDown ? null : { transform: 'rotate(180deg)' }}
                    />}
                    
            </button>
            {
                isDropDown &&
                <NavDropdown
                    dropDownItems={navItem.dropDownItems}
                // handleDropdownClick={(event) => handleDropdownClick(event.target.id)}
                />
            }
        </li>
    )
};

const mapDispatchToProps = (dispatch) => ({
    setBurgerHidden: () =>
        dispatch(setBurgerHidden()),
})

export default connect(null, mapDispatchToProps)(NavItem);