import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import "./report.styles.scss";

import ReportHeader from "../report-header/report-header.component"
import ReportSideBar from "../report-side-bar/report-side-bar.component"
import ReportBody from "../report-body/report-body.component";
import { useEffect } from "react";
import { useState } from "react";
import { selectReportInfo } from "../../redux/report/report.reselect";
import { setReportSideBarNavActiveItem } from "../../redux/report/report.actions";
import { setLoadedData } from "../../redux/list/list.actions";
import { setFilterEmpty } from "../../redux/filter/filter.actions";


const Report = ({
    reportInfo,
    reportColumn,
    endpoint,
    dataKeys,
    setReportSideBarNavActiveItem,
    setLoadedData,
    setFilterEmpty
}) => {
    const [isSideNavOn, setIsSideNavOn] = useState(true);

    const handleSideNav = () => {
        setIsSideNavOn((prevState) => !prevState)
    }

    useEffect(() => {
        return () => {
            setReportSideBarNavActiveItem(0);
        }
    }, [])

    useEffect(() => {
        setLoadedData({ data: null, skip: null, totalNumItems: 0 });
        setFilterEmpty();
    }, [])

    return (
        <div className="report">
            <ReportHeader
                reportTitle={reportInfo && reportInfo.reportTitle}
                handleSideNav={handleSideNav}
            />
            <div className="report__nav-body">
                {isSideNavOn &&
                    <form className="report__nav-body__nav">
                        <ReportSideBar />
                    </form>
                }
                <div className={`report__nav-body__body ${isSideNavOn ? null : "report__nav-body__body--full"}`} >
                    <ReportBody
                        reportColumn={reportColumn}
                        endpoint={endpoint}
                        dataKeys={dataKeys}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    reportInfo: selectReportInfo,
});

const mapDispatchToProps = dispatch => ({
    setReportSideBarNavActiveItem: (data) =>
        dispatch(setReportSideBarNavActiveItem(data)),
    setLoadedData: (data) =>
        dispatch(setLoadedData(data)),
    setFilterEmpty: () =>
        dispatch(setFilterEmpty()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report));