import IconPurchasing from "../../assets/icons/purchasing.svg";
import IconInventory from "../../assets/icons/inventory.svg";
import IconSales from "../../assets/icons/sales.svg";
import IconReport from "../../assets/icons/report.svg";

import { FaCartShopping } from "react-icons/fa6";
import { MdInventory } from "react-icons/md";
import { RiShoppingBag3Fill } from "react-icons/ri";
import { BsFileBarGraphFill } from "react-icons/bs";


export const navItems1 = [
    {
        title: "Sales",
        icon: FaCartShopping,
        items: [
            {
                text: 'Sales Orders',
                page: '/sales-orders'
            },
            {
                text: 'Customers',
                page: '/customers'
            }
        ]
    },
    {
        title: "Stock",
        icon: MdInventory,
        items: [
            {
                text: 'Products',
                page: '/items'
            },
            {
                text: 'Stock Transfers',
                page: '/stock-transfers'
            },
            {
                text: 'Stock Adjustments',
                page: '/stock-adjustments'
            },
            {
                text: 'Work Orders',
                page: '/work-orders'
            },
        ]
    },
    {
        title: "Purchasing",
        icon: RiShoppingBag3Fill,
        items: [
            {
                text: 'Purchase Orders',
                page: '/purchase-orders'
            },
            {
                text: 'Suppliers',
                page: '/suppliers'
            },
        ]
    }
]

export const navItems2 = [
    {
        title: "Report",
        icon: BsFileBarGraphFill,
        items: [
            {
                text: 'Sales Report',
                page: '/sales-reports'
            },
            {
                text: 'Stock Report',
                page: '/inventory-reports'
            },
            {
                text: 'Purchasing Report',
                page: '/purchase-reports'
            }
        ]
    }
]



