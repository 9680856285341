import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { useMediaQuery } from 'react-responsive';

export const PACK_INFO = {
  formTitle: "Sales Order",
  path: "/salesorders",
  subPath: "/packs",
  listPage: "/sales-orders",
}

export const PACK_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "salesOrder",
      fieldItems: [
        {
          label: "SO Number",
          type: "text",
          name: "salesOrderNumber",
          initialFocus: true,
          disabled: true,
          defaultValue: `SO-.YYYY.-`
        },
        {
          label: "SO Date",
          type: "date",
          name: "salesOrderDate",
          initialFocus: false,
          disabled: true,
          validationProps: {
            required: "SO date is required"
          }
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   disabled: true,
        //   muliselect: false,
        //   url: "/users"
        // }
      ]
    },
    tableForm: {
      sectionType: "Items",
      sectionTypeId: "tableForm",
      fieldName: "items",
      expandable: true,
      isExpand: true,
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: true,
      tableColumns: [
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Product Code/Name"}
                required={true}
              />
            )
          },
          accessor: "item",
          width: 200,
          Cell: ({ row }) => {
            const endpointUrl = "/items";
            const validationProps = {
              required: "Product Code/Name is required"
            }
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableSelect

              fieldName={"items"}
              fieldItemName={"Item"}
              rowNumber={row.id}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Product Code/Name"}
              isMobileView={isMobileView}
              shouldTriggerSelectedField={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"UoM"}
                required={true}
              />
            )
          },
          accessor: "unitOfMeasure",
          width: 120,
          Cell: ({ row }) => {
            const endpointUrl = "/unitofmeasures";
            const validationProps = {
              required: "UoM is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"UnitOfMeasure"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={true}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Qty Packed"}
                required={true}
              />
            )
          },
          accessor: "qtyPacked",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Qty packed is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"qtyPacked"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
              headerName={"Qty Packed"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Box Number"}
                required={false}
              />
            )
          },
          accessor: "boxNumber",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Box number is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"boxNumber"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={validationProps}
              align="right"
              disabled={row.isDisable}
              headerName={"Box Number"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Packed By"}
                required={false}
              />
            )
          },
          accessor: "packedBy",
          width: 90,
          Cell: ({ row }) => {
            const isMobileView = useMediaQuery({ maxWidth: 991 });

            if (isMobileView) {
              return null;
            }

            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"packedBy"}
              rowNumber={row.id}
              validationProps={false}
              disabled={true}
              align="left"
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Packed Date"}
                required={true}
              />
            )
          },
          accessor: "packedDate",
          width: 80,
          Cell: ({ row }) => {
            const validationProps = {
              required: "Packed date is required"
            }

            const isMobileView = useMediaQuery({ maxWidth: 991 });

            return (<FormTableDatePicker
              fieldName={"items"}
              fieldItemName={"packedDate"}
              rowNumber={row.id}
              value={row.value}
              validationProps={validationProps}
              disabled={row.isDisable}
              headerName={"Packed Date"}
              isMobileView={isMobileView}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
  },
  watchFields: []
}
